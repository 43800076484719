import React from 'react';
import moment from 'moment';
import {Buttons, Grid, Icon, Info, Inputs, Typography} from 'components';
import {useMarel, useUser} from 'hooks';
import createPersistedState from 'use-persisted-state';
import {amount, modal} from 'utils';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    background: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3, 3, 2),
        margin: theme.spacing(1, 0),
        background: theme.palette.white,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        }
    },
    container: {
        padding: theme.spacing(2, 0),
        '& i': {
            marginBottom: theme.spacing(1)
        }
    },
    wrapper: {
        width: '100%',
        maxWidth: 200,
        margin: 'auto'
    },
    list: {
        '& li': {
            marginLeft: theme.spacing(2),
            display: 'list-item',
            padding: 2,
            '&:last-child': {
                paddingBottom: theme.spacing(2)
            }
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: -30,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginTop: 30
        }
    },
    fullRate: {
        whiteSpace: 'nowrap',
        paddingTop: theme.spacing(1)
    }
}));

const useAgeIndex = createPersistedState('EP_ageIndex');

function StepMarelResult({setStep}) {
    const classes = useStyles();

    const {user} = useUser();
    const {marel} = useMarel();

    const [ageIndex, setAgeIndex] = useAgeIndex(0);

    if (!marel.result) return null;

    return (
        <div className="full-width">
            <Typography
                color="blue"
                variant="h1"
            >
                Ma retraite de base et complémentaire
            </Typography>

            <div className={classes.background}>
                <Typography
                    variant="h6"
                    center
                    style={{maxWidth: 450}}
                >
                    Ce scénario a été créé le&nbsp;
                    <Typography
                        component="span"
                        semibold
                    >
                        {moment(marel.date).format('DD MMMM YYYY')}&nbsp;
                    </Typography>
                    à partir de mes données personnelles et de mes informations de carrière{' '}
                    <Info
                        hash="marel_result_infos"
                        color="blueLight"
                        title="Informations"
                    >
                        <Typography
                            variant="h4"
                            semibold
                        >
                            Précisions sur les montants présentés :
                        </Typography>
                        <ul className={classes.list}>
                            <li>
                                Les montants sont calculés à partir des informations connues de vos régimes de retraite
                                et des données saisies dans le simulateur.
                            </li>
                            <li>
                                À partir de votre situation actuelle, une évolution régulière de vos revenus est
                                appliquée automatiquement.
                            </li>
                            <li>
                                Sans prise en compte d’une durée spécifique d'interruption d'activité liée à la
                                naissance de vos enfants (si des enfants ont été déclarés).
                            </li>
                        </ul>
                    </Info>
                </Typography>
                <Grid
                    className={classes.container}
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        sm={4}
                        xs={12}
                    >
                        <Icon
                            name="axa-icons-man-elderly-o"
                            color="blueLight"
                            size={50}
                            flex
                            circled
                        />
                        <Typography
                            variant="h6"
                            center
                        >
                            En partant à
                        </Typography>
                        <Typography
                            variant="h3"
                            color="blueLight"
                            center
                            semibold
                        >
                            {marel.result[ageIndex || 0].age} ans *
                        </Typography>
                        <div className={classes.wrapper}>
                            <Inputs.Slider
                                name="age_index"
                                min={0}
                                max={marel.result.length - 1}
                                minLabel={`${marel.result[0].age} ans`}
                                maxLabel={`${marel.result[marel.result.length - 1].age} ans`}
                                defaultValue={ageIndex || 0}
                                onInput={(event) => setAgeIndex(parseInt(event.target.value))}
                                reduced
                                variant
                            />
                            <Typography
                                className={classes.fullRate}
                                variant="h6"
                                color="blueLight"
                                center
                            >
                                * Taux plein : {marel.age_full_rate}{' '}
                                <Info
                                    hash="full_rate_disclaimer"
                                    color="blueLight"
                                    tooltip
                                    title="Taux plein"
                                >
                                    Âge auquel vous pourrez bénéficier d'une pension de retraite de base à taux plein
                                    (sans décote/minoration)
                                </Info>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                    >
                        <Icon
                            name="axa-icons-growth-o"
                            color="blueLight"
                            size={50}
                            flex
                            circled
                        />
                        <Typography
                            variant="h6"
                            center
                        >
                            Avec
                        </Typography>
                        <Typography
                            variant="h3"
                            color="blueLight"
                            center
                            semibold
                            className="margin-0"
                        >
                            {marel.result[ageIndex || 0].quarters}
                        </Typography>
                        <Typography
                            variant="h3"
                            color="blueLight"
                            center
                        >
                            trimestres
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                    >
                        <Icon
                            name="axa-icons-coins-euro-o"
                            color="blueLight"
                            size={50}
                            flex
                            circled
                        />
                        <Typography
                            variant="h6"
                            center
                        >
                            Je pourrais avoir droit à
                        </Typography>
                        <Typography
                            variant="h3"
                            color="blueLight"
                            center
                            semibold
                            className="margin-0"
                        >
                            {amount.format(
                                (marel.result[ageIndex || 0].yearly_amount_base +
                                    marel.result[ageIndex || 0].yearly_amount_complementary) /
                                    12
                            )}
                        </Typography>
                        <Typography
                            variant="h3"
                            color="blueLight"
                            center
                        >
                            bruts/mois
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.buttons}>
                    <div>
                        <Buttons.Chevron
                            label="Refaire ma simulation"
                            color="blueLight"
                            center
                            onClick={() => setStep(0)} // StepIntro
                        />
                        <Buttons.Default
                            label="Retour au tableau de bord"
                            color="orangeDark"
                            next
                            center
                            onClick={() => {
                                modal.close('marel');
                                if (user.origin === 'individual')
                                    window.scrollTo({
                                        top: 280,
                                        behavior: 'smooth'
                                    });
                            }}
                            style={{width: 220}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StepMarelResult;
