import React from 'react';
import PropTypes from 'prop-types';
import {Info} from 'components';
import {useMarel} from 'hooks';
import {amount} from 'utils';

import {makeStyles} from '@material-ui/styles';
import moment from 'moment/moment';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto hidden'
    },
    table: {
        width: '100%',
        minWidth: 600,
        margin: theme.spacing(0, 0, 2),
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        textAlign: 'center',
        '& thead': {
            background: theme.palette.purple,
            '& th': {
                height: 40,
                fontSize: 16,
                fontWeight: '600'
            }
        },
        '& tbody': {
            background: theme.palette.white,
            '& tr': {
                height: 40,
                borderTop: `solid 3px ${theme.palette.pale}`,
                '& td': {
                    fontSize: 16,
                    '&:first-child': {
                        fontWeight: '600'
                    }
                }
            }
        },
        '& tfoot': {
            background: theme.palette.purple,
            '& td': {
                fontSize: 16,
                fontWeight: '600',
                '&:first-child': {
                    padding: theme.spacing(1, 2),
                    textAlign: 'left'
                }
            }
        }
    }
}));

function CareerTable(props) {
    const {careerType} = props;
    const classes = useStyles();

    const {marel} = useMarel();

    if (!marel.careers) return null;

    const career = marel.careers[careerType];

    return (
        <div className={classes.root}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>Période</th>
                        <th>Nom du régime</th>
                        {careerType === 'base' && <th>Employeur ou nature de la période</th>}
                        {careerType === 'base' && (
                            <th>
                                Salaires annuels
                                <br />
                                plafonnés &nbsp;
                                <Info
                                    flex
                                    hash="ceiling_disclaimer"
                                    title="Information"
                                    color="blue"
                                    size={14}
                                    tooltip
                                >
                                    Plafond de la Sécurité Sociale
                                </Info>
                            </th>
                        )}
                        {careerType === 'complementary' && <th>Nature de la période</th>}
                        {careerType === 'base' && <th>Trimestres</th>}
                        {careerType === 'complementary' && <th>Points</th>}
                    </tr>
                </thead>
                <tbody>
                    {career
                        .sort((p1, p2) => new Date(p1.start_date) - new Date(p2.start_date))
                        .map((experience, index) => (
                            <tr key={`experience-${index}`}>
                                <td>
                                    Du&nbsp;{moment(new Date(experience.start_date)).format('DD/MM/YYYY')} au&nbsp;
                                    {moment(new Date(experience.end_date)).format('DD/MM/YYYY')}
                                </td>
                                <td title={experience.regime_label}>{experience.regime}</td>
                                <td>{experience.employer}</td>
                                {careerType === 'base' && (
                                    <td>
                                        {!experience.yearly_salary || experience.yearly_salary === 0
                                            ? '-'
                                            : amount.format(
                                                  experience.yearly_salary,
                                                  0,
                                                  experience.currency === 'E' ? '€' : 'Frs'
                                              )}
                                    </td>
                                )}
                                {careerType === 'base' && <td>Non renseigné</td>}
                                {careerType === 'complementary' && <td>{experience.points.toFixed(2)}</td>}
                            </tr>
                        ))}
                </tbody>
                <tfoot>
                    {careerType === 'base' && (
                        <tr>
                            <td colSpan={4}>Total du nombre de trimestres par régimes</td>
                            <td>
                                {career.map((experience) => experience.quarters).reduce((acc, val) => acc + val, 0)}
                            </td>
                        </tr>
                    )}
                </tfoot>
            </table>
        </div>
    );
}

CareerTable.propTypes = {
    careerType: PropTypes.string.isRequired
};

export default CareerTable;
